/* loader */
.loader-modal{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0,0,0,.7);
    z-index: 9;
}
.loaderContainer{
    width: 65px;
    height: 65px;
    position: absolute;
    overflow: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}


.loaderContainer{text-align: center;}
.modal-heading{display: block !important;}
.loaderContainer .modal-heading div svg g g{stroke-width: 2px;}
.loaderContainer .modal-heading div svg g g circle{stroke-opacity: .4;}